<template>
  <div class="dashboard-container">
    <Card />
    <Sale />
    <Observe />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import Card from "./Card";
import Sale from "./Sale";
import Observe from "./Observe";

export default {
  name: "Dashboard",
  components: {
    Card,
    Sale,
    Observe,
  },
  methods: {
    getData(){
      setTimeout(() => {
        console.log(this)
        this.$store.dispatch("getData")
      }, 1000);
    }
  },
  mounted(){
    this.getData();
  },
  created() {
    this.getData();
  },
};
</script>

<style lang="less" scoped>
// .dashboard {
//     &-container {
//       margin: 30px;
//     }
//   &-text {
//     font-size: 30px;
//     line-height: 46px;
//   }
// }
</style>
