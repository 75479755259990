<template>
  <div class="charts" ref="charts"></div>
</template>

<script>
import * as echarts from "echarts";
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      dashboard: (state) => state.dashboard,
    }),
  },
  data() {
    return {
      lineCharts: null,
    };
  },
  mounted() {
    this.lineCharts = echarts.init(this.$refs.charts);
    this.lineCharts.setOption({
      xAxis: {
        show: false,
        type: "category",
      },
      yAxis: {
        show: false,
      },
      series: [
        {
          type: "line",
          data: [],
          itemStyle: {
            opacity: 0,
          },
          lineStyle: {
            color: "#7058a3",
          },
          areaStyle: {
            color: {
              type: "linear",
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: "#7058a3", // 0% 处的颜色
                },
                {
                  offset: 1,
                  color: "#fff", // 100% 处的颜色
                },
              ],
              global: false, // 缺省为 false
            },
          },
          smooth: true,
        },
      ],
      grid: {
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
      },
    });
  },
  watch: {
    dashboard() {
      let data = this.dashboard.visit_week.map((item)=>{
        return item.total
      })
      this.lineCharts.setOption({
        series: [
          {
            data: data
          },
        ],
      });
    },
  },
};
</script>

<style>
.charts {
  width: 100%;
  height: 100%;
}
</style>
