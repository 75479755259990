<template>
  <div>
    <el-row :gutter="10">
      <!-- <el-col :span="8">
        <Search />
      </el-col> -->
      <el-col :span="24">
        <MapChart
          :cdata="mapData"
          :chartId="'mapChart'"
          :width="'800px'"
          :height="'900px'"
        />
      </el-col>
    </el-row>
  </div>
</template>

<script>
import Search from "./Search";
// import Category from "./Category";
import MapChart from "./map/MapChart";
export default {
  components: {
    Search,
    MapChart,
  },
  data(){
    return {
      cityGroup: [
        "寿春镇",
        "双桥镇",
        "涧沟镇",
        "丰庄镇",
        "正阳关镇",
        "迎河镇",
        "板桥镇",
        "安丰塘镇",
        "堰口镇",
        "保义镇",
        "隐贤镇",
        "安丰镇",
        "众兴镇",
        "茶庵镇",
        "三觉镇",
        "炎刘镇",
        "刘岗镇",
        "双庙集镇",
        "小甸镇",
        "瓦埠镇",
        "刘岗镇",
        "大顺镇",
        "八公山乡",
        "张李乡",
        "窑口镇",
        "陶店乡",
      ]
      // mapData:[]
    }
  },
  computed: {
    mapData() {
      let seriesData = this.cityGroup.map((item) => {
        return { name: item, value: Math.ceil(Math.random() * 30) };
      });
      return seriesData;
    },
  }
};
</script>

<style></style>
