<template>
  <div class="charts" ref="charts"></div>
</template>

<script>
import * as echarts from "echarts";
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      listState: (state) => state.home.list,
    }),
  },
  data() {
    return {
      lineCharts: null,
    };
  },
  mounted() {
    this.lineCharts = echarts.init(this.$refs.charts);
    this.lineCharts.setOption({
      xAxis: {
        show: false,
        min: 0,
        max: 100,
      },
      yAxis: {
        show: false,
        type: "category",
      },
      series: [
        {
          type: "bar",
          data: [],
          barWidth: 10,
          color: "yellowgreen",
          showBackground: true,
          backgroundStyle: {
            color: "#eee",
          },
          //文本标签
          label: {
            show: true,
            //改变文本内容
            formatter: "|",
            position: "right",
          },
        },
      ],
      grid: {
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
      },
      tooltip: {},
    });
  },
  watch: {
    listState() {
      this.lineCharts.setOption({
        series: [
          {
            data: [this.listState.activityRate],
          },
        ],
      });
    },
  },
};
</script>

<style>
.charts {
  width: 100%;
  height: 100%;
}
</style>
