<template>
  <el-card>
    <div slot="header" class="header">
      <div class="category-header">会员地区分布</div>
    </div>
    <div class="main" style="text-align: left">
      <span class="main-title"
        >会员总数<span style="font-weight: bold; color: red">{{
          dashboard ? dashboard.user_total : 0
        }}</span></span
      >
      <span class="main-content"
        >今日新增 <span>{{ dashboard ? dashboard.user_today : 0 }}</span>
        <svg
          t="1681137762109"
          class="icon"
          viewBox="0 0 1024 1024"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          p-id="2607"
          width="16"
          height="16"
        >
          <path
            d="M194.108395 621.186771 863.223758 621.186771 528.668123 251.67295Z"
            fill="#d81e06"
            p-id="2608"
          ></path>
        </svg>
      </span>
    </div>
    <div class="charts">
      <Echart
        :id="chartId"
        :ref="chartId"
        :options="options"
        :height="height"
        :width="width"
      />
    </div>
  </el-card>
</template>

<script>
import * as echarts from "echarts";
import Echart from "../echart";
import { mapState } from "vuex";
export default {
  components: {
    Echart,
  },
  props: {
    cdata: {
      type: Array,
      default: () => [],
    },
    chartId: {
      type: String,
      default: "mapChart",
    },
    width: {
      type: String,
      default: "600px",
    },
    height: {
      type: String,
      default: "700px",
    },
  },
  computed: {
    ...mapState({
      dashboard: (state) => state.dashboard,
    }),
  },
  data() {
    return {
      options: {},
    };
  },
  watch: {
    cdata: {
      handler(newData) {
        // 设置点的位置(经纬度)
        const geoCoordMap = {
          寿春镇: [116.7985594, 32.504504, 20],
          双桥镇: [116.7146218, 32.4929573, 20],
          涧沟镇: [116.6505797, 32.5162463, 20],
          丰庄镇: [116.5857363, 32.5051381, 20],
          正阳关镇: [116.5382664, 32.4071099, 20],
          迎河镇: [116.5500522, 32.3116108, 20],
          板桥镇: [116.6347085, 32.3306105, 20],
          安丰塘镇: [116.6870977, 32.3312207, 20],
          堰口镇: [116.7936727, 32.3578894, 20],
          保义镇: [116.7488293, 32.2461202, 20], //
          隐贤镇: [116.5716115, 32.1389891, 20], //
          安丰镇: [116.7411398, 32.1716981, 20], //
          众兴镇: [116.6527465, 32.0777274, 20], //
          茶庵镇: [116.7753472, 32.0797161, 20], //
          三觉镇: [116.7793716, 31.9975627, 20], //
          炎刘镇: [116.8887493, 32.0700476, 20], //
          刘岗镇: [116.9814544, 32.0798078, 20], //
          双庙集镇: [116.8832158, 32.184595, 20], //
          小甸镇: [116.9979114, 32.2565637, 20], //
          瓦埠镇: [116.936611, 32.3053699, 20], //
          大顺镇: [117.0247168, 32.3492804, 20], //
          八公山乡: [116.7780672, 32.6092165, 20], //
          张李乡: [116.5667248, 32.2221138, 20], //
          窑口镇: [116.7963317, 32.4573187, 20], //
          陶店乡: [116.8658964, 32.3670926, 20], //
          // 寿县工业园区: [116.7578194, 32.5533092, 20],
          // 寿西湖农场: [116.7345747, 32.5518211, 20],
          // 正阳关农场: [116.5341630, 32.4604010, 20]
        };
        const seriesData = [
          {
            name: "寿春镇",
          },
          {
            name: "双桥镇",
          },
          {
            name: "涧沟镇",
          },
          {
            name: "丰庄镇",
          },
          {
            name: "正阳关镇",
          },
          {
            name: "迎河镇",
          },
          {
            name: "板桥镇",
          },
          {
            name: "安丰塘镇",
          },
          {
            name: "堰口镇",
          },
          {
            name: "保义镇",
          },
          {
            name: "隐贤镇",
          },
          {
            name: "安丰镇",
          },
          {
            name: "众兴镇",
          },
          {
            name: "茶庵镇",
          },
          {
            name: "三觉镇",
          },
          {
            name: "炎刘镇",
          },
          {
            name: "刘岗镇",
          },
          {
            name: "双庙集镇",
          },
          {
            name: "小甸镇",
          },
          {
            name: "瓦埠镇",
          },
          {
            name: "刘岗镇",
          },
          {
            name: "大顺镇",
          },
          {
            name: "八公山乡",
          },
          {
            name: "张李乡",
          },
          {
            name: "窑口镇",
          },
          {
            name: "陶店乡",
          },
          // {
          //   name: '寿县工业园区'
          // },
          // {
          //   name: '寿西湖农场'
          // },
          // {
          //   name: '正阳关农场'
          // }
        ];
        const convertData = function (data) {
          const scatterData = [];
          for (var i = 0; i < data.length; i++) {
            var geoCoord = geoCoordMap[data[i].name];
            if (geoCoord) {
              scatterData.push({
                name: data[i].name,
                value: geoCoord.concat(data[i].value),
              });
            }
          }
          return scatterData;
        };
        this.options = {
          title: {
            text: "",
            left: 20,
            top: 20,
            textStyle: {
              color: "#fff",
            },
          },
          showLegendSymbol: true,
          tooltip: {
            trigger: "item",
            textStyle: {
              fontSize: 14,
              lineHeight: 22,
            },
            position: (point) => {
              // 固定在顶部
              return [point[0] + 50, point[1] - 20];
            },
            // 如果需要自定义 tooltip样式，需要使用formatter
            /*
              formatter: params => {
                return `<div style=""> ... </div>`
              }
            */
          },
          visualMap: {
            min: 10,
            max: 200,
            show: true,
            text: ["多", "少"],
            textStyle: {
              color: "#999",
            },
            seriesIndex: 0,
            // 颜色
            inRange: {
              color: ["rgba(41,166,206, .5)", "rgba(69,117,245, .9)"],
            },
          },
          // 底部背景
          geo: {
            show: true,
            aspectScale: 0.85, // 长宽比
            zoom: 1.2,
            top: "10%",
            left: "16%",
            map: "寿县",
            roam: false,
            itemStyle: {
              normal: {
                areaColor: "rgba(0,0,0,0)",
                shadowColor: "rgba(7,114,204, .8)",
                shadowOffsetX: 5,
                shadowOffsetY: 5,
              },
              emphasis: {
                areaColor: "#00aeef",
              },
            },
          },
          series: [
            {
              name: "会员数",
              type: "map",
              aspectScale: 0.85, // 长宽比
              zoom: 1.2,
              mapType: "寿县", // 自定义扩展图表类型
              top: "10%",
              left: "16%",
              itemStyle: {
                normal: {
                  color: "red",
                  areaColor: "rgba(19,54,162, .5)",
                  borderColor: "rgba(0,242,252,.3)",
                  borderWidth: 1,
                  shadowBlur: 7,
                  shadowColor: "#00f2fc",
                },
                emphasis: {
                  areaColor: "#4f7fff",
                  borderColor: "rgba(0,242,252,.6)",
                  borderWidth: 2,
                  shadowBlur: 10,
                  shadowColor: "#00f2fc",
                },
              },
              label: {
                formatter: (params) => `${params.name}`,
                show: true,
                position: "insideRight",
                textStyle: {
                  fontSize: 14,
                  color: "#666",
                },
                emphasis: {
                  textStyle: {
                    color: "#666",
                  },
                },
              },
              data: newData,
            },
            {
              type: "effectScatter",
              coordinateSystem: "geo",
              symbolSize: 7,
              effectType: "ripple",
              legendHoverLink: false,
              showEffectOn: "render",
              rippleEffect: {
                period: 4,
                scale: 2.5,
                brushType: "stroke",
              },
              zlevel: 1,
              itemStyle: {
                normal: {
                  color: "#99FBFE",
                  shadowBlur: 5,
                  shadowColor: "#fff",
                },
              },
              data: convertData(seriesData),
            },
          ],
        };
        // 重新选择区域
        this.handleMapRandomSelect();
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    // 开启定时器
    startInterval() {
      const _self = this;
      // 应通过接口获取配置时间，暂时写死5s
      const time = 2000;
      if (this.intervalId !== null) {
        clearInterval(this.intervalId);
      }
      this.intervalId = setInterval(() => {
        _self.reSelectMapRandomArea();
      }, time);
    },
    // 重新随机选中地图区域
    reSelectMapRandomArea() {
      const length = 9;
      this.$nextTick(() => {
        try {
          const map = this.$refs.centreLeft2ChartRef.chart;
          let index = Math.floor(Math.random() * length);
          while (index === this.preSelectMapIndex || index >= length) {
            index = Math.floor(Math.random() * length);
          }
          map.dispatchAction({
            type: "mapUnSelect",
            seriesIndex: 0,
            dataIndex: this.preSelectMapIndex,
          });
          map.dispatchAction({
            type: "showTip",
            seriesIndex: 0,
            dataIndex: index,
          });
          map.dispatchAction({
            type: "mapSelect",
            seriesIndex: 0,
            dataIndex: index,
          });
          this.preSelectMapIndex = index;
        } catch (error) {
          console.log(error);
        }
      });
    },
    handleMapRandomSelect() {
      this.$nextTick(() => {
        try {
          const map = this.$refs.centreLeft2ChartRef.chart;
          const _self = this;
          setTimeout(() => {
            _self.reSelectMapRandomArea();
          }, 0);
          // 移入区域，清除定时器、取消之前选中并选中当前
          map.on("mouseover", function (params) {
            clearInterval(_self.intervalId);
            map.dispatchAction({
              type: "mapUnSelect",
              seriesIndex: 0,
              dataIndex: _self.preSelectMapIndex,
            });
            map.dispatchAction({
              type: "mapSelect",
              seriesIndex: 0,
              dataIndex: params.dataIndex,
            });
            _self.preSelectMapIndex = params.dataIndex;
          });
          // 移出区域重新随机选中地图区域，并开启定时器
          map.on("globalout", function () {
            _self.reSelectMapRandomArea();
            _self.startInterval();
          });
          this.startInterval();
        } catch (error) {
          console.log(error);
        }
      });
    },
  },
};
</script>
<style scoped>
.header {
  /* border-bottom: 1px solid #eee; */
  padding-top: -15px;
}
.category-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.charts {
  width: 100%;
  /* background: #f1f1f1; */
  display: flex;
  justify-content: center;
}

.main {
  margin: 10px 0px;
}

.main-title {
  margin-right: 30px;
  font-size: 30px;
}
.main-content {
  margin-right: 10px;
  color: #8a8a8a;
}
.main-content svg {
  vertical-align: middle;
}
</style>
