<template>
  <div>
    <div class="card-header">
      <span>{{ title }}</span>
      <i :class="icon"></i>
    </div>
    <div class="cart-conten">{{ count }}</div>
    <div class="cart-charts">
      <slot name="charts"></slot>
    </div>
    <div class="cart-footer">
      <slot name="footer"></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: ["title", "count", "icon"],
};
</script>

<style>
.card-header {
  display: flex;
  justify-content: space-between;
  color: #8a8a8a;
}
.cart-conten {
  font-size: 30px;
  padding: 10px 0px;
}
.cart-charts {
  height: 50px;
}
.cart-footer {
  border-top: solid 1px #eee;
  padding-top: 10px;
}
</style>
