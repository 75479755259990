<template>
  <div>
    <el-row :gutter="10">
      <el-col :span="6">
        <el-card>
          <Detail title="公众号累计访问量（人次）" :count="dashboard.visit_total">
            <template slot="charts">
              <lineChart/>
            </template>
            <template slot="footer">
              <span>今日访问量 <span style="color:red;font-weight:bold">{{ dashboard.visit_today }}</span> （人次）</span>
            </template>
          </Detail>
        </el-card>
      </el-col>
      <el-col :span="6">
        <el-card>
          <Detail title="农资商城累计销售额(元)" :count="`￥ ${  $round(dashboard.shop_total) }`">
            <!-- <template slot="charts">
              <bar-charts :chartData="dashboard && dashboard.shop_week.length?dashboard.shop_week:[]" />
            </template> -->
            <template slot="footer">
              <span>今日销售额￥ <span style="color:red;font-weight:bold">{{ $round(dashboard.shop_today) }}</span> </span>
            </template>
          </Detail>
        </el-card>
      </el-col>
      <el-col :span="6">
        <el-card>
          <Detail title="农机服务累计金额" :count=" `￥ ${  $round(dashboard.service_total) }`">
            <template slot="charts">
              <bar-charts :chartData="dashboard && dashboard.service_week?dashboard.service_week:[]" />
            </template>
            <template slot="footer">
              <span>今日完成金额￥ <span style="color:red;font-weight:bold">{{ $round(dashboard.service_today) }}</span></span>
            </template>
          </Detail>
        </el-card>
      </el-col>

      <el-col :span="6">
        <el-card>
          <Detail title="农场累计认养金额(元)" :icon="`el-icon-share`" :count="`￥ ${ $round(dashboard.farm_total) }`">
            <template slot="footer">
              <span>今日认养金额￥ <span style="color:red;font-weight:bold">{{ $round(dashboard.farm_today) }}</span> </span>
            </template>
          </Detail>
        </el-card>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import Detail from "./Detail";
import barCharts from "./barCharts";
import lineChart from "./lineChart";
import progressCharts from "./progressCharts";
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState({
      dashboard: (state) => state.dashboard,
    }),
  },
  components: {
    Detail,
    lineChart,
    barCharts,
    progressCharts,
  },
};
</script>

<style lang="less" scoped>
.salebox {
  padding-top: 20px;
  span {
    font-size: 14px;
  }
  svg {
    vertical-align: middle;
  }
}

.salebox2 {
  svg {
    vertical-align: middle;
  }
}
</style>
