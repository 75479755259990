<template>
  <div class="charts" ref="charts"></div>
</template>

<script>
import * as echarts from "echarts";
import { mapState } from "vuex";

export default {
  props: {
    chartData: Array,
  },
  // computed: {
  //   ...mapState({
  //     listState: (state) => state.home.list,
  //   }),
  // },
  data() {
    return {
      lineCharts: null,
    };
  },
  mounted() {
    this.lineCharts = echarts.init(this.$refs.charts);
    this.lineCharts.setOption({
      xAxis: {
        show: false,
        type: "category",
      },
      yAxis: {
        show: false,
      },
      series: [
        {
          type: "bar",
          data: [],
        },
      ],
      grid: {
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
      },
      tooltip: {},
    });
  },
  watch: {
    chartData: {
      handler(nval, oval) {
        if (this.chartData) {
          let data = this.chartData.map((item) => {
            return this.$round(item.total);
          });
          console.log(data);
          this.lineCharts.setOption({
            series: [
              {
                data: data,
              },
            ],
          });
        }
      },
      deep: true,
      immediate: false
    },
  },
};
</script>

<style>
.charts {
  width: 100%;
  height: 100%;
}
</style>
