<template>
  <el-card>
    <div slot="header" class="hearder">
      <div class="search-header">
        <span>会员总览</span>
      </div>
    </div>

    <div>
      <el-row :gutter="10">
        <el-col :span="24">
          <lineCharts />
        </el-col>
        <!-- <el-col :span="12">
          <lineChartsRight />
        </el-col> -->
      </el-row>
    </div>
  </el-card>
</template>

<script>
import lineCharts from "./lineCharts";
import lineChartsRight from "./lineChartsRight";
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState({
      listState: (state) => state.home.list,
    }),
  },
  components: {
    lineCharts,
    lineChartsRight,
  },
  data() {
    return {
      page: 1,
      tableData: [],
    };
  },
  watch: {
    listState() {
      this.listState.searchWord.map((item, index) => {
        return (item.id = index + 1);
      });
      this.tableData = this.listState.searchWord;
    },
  },
};
</script>

<style scoped>
.search-header {
  display: flex;
  justify-content: space-between;
  /* padding-bottom: 10px; */
  height: 28px;
  align-items: center;
}
.hearder {
  border-bottom: 1px solid #eee;
}
.pagenation {
  float: right;
}
</style>
