<template>
  <el-card class="box-card" style="margin: 10px 0">
    <div slot="header" class="clearfix">
      <div style="font-size:20px;font-weight:bold">农资商城品类销售汇总</div>
      <!-- <el-tabs class="tab" v-model="activename"> -->
        <!-- <el-tab-pane label="农资商城品类销售汇总" name="sale"></el-tab-pane> -->
        <!-- <el-tab-pane label="访问量" name="visite"></el-tab-pane> -->
      <!-- </el-tabs> -->
      <!-- <div class="right">
        <span @click="setDay" class="choose-item">今日</span>
        <span @click="setWeek" class="choose-item">本周</span>
        <span @click="setMonth" class="choose-item">本月</span>
        <span @click="setYear" class="choose-item">本年</span>
        <el-date-picker
          class="date"
          type="daterange"
          range-separator="-"
          start-placeholder="开始月份"
          end-placeholder="结束月份"
          size="mini"
          v-model="date"
          value-format="yyyy-MM-dd"
        >
        </el-date-picker>
      </div> -->
    </div>

    <div>
      <el-row>
        <el-col :span="18">
          <div class="charts" ref="charts"></div>
        </el-col>
        <el-col :span="6" class="right">
          <h4 style="padding: 0px; margin: 0px">农资商品销售额排名</h4>
          <ul>
            <li v-for="(item, index) in rankList" :key="index">
              <span :class="(index) > 2 ? 'rindex2' : 'rindex'">{{
                index + 1
              }}</span>
              <span>{{ item.name }}</span>
              <span class="rvalue">￥ {{ $round(item.total) }}</span>
            </li>
          </ul>
        </el-col>
      </el-row>
    </div>
  </el-card>
</template>

<script>
import * as echarts from "echarts";
import dayjs from "dayjs";
import { mapState } from "vuex";
export default {
  data() {
    return {
      activename: "sale",
      mycharts: null,
      date: [],
      rankList: [],
    };
  },
  computed: {
    ...mapState({
      dashboard: (state) => state.dashboard,
    })
  },
  mounted() {
    this.mycharts = echarts.init(this.$refs.charts);
    this.mycharts.setOption({
      title: {
        text: "月度销售额趋势",
      },
      xAxis: {
        type: "category",
        data: [],
        axisTick: {
          alignWithLabel: true,
        },
      },
      yAxis: {
        type: "value",
        min: 0,
        max: 10,
      },
      series: [
        {
          data: [],
          type: "bar",
          barWidth: "30%",

          //   showBackground: true,
          //   backgroundStyle: {
          //     color: "rgba(180, 180, 180, 0.2)",
          //   },
        },
      ],
      grid: {
        left: "3%",
        right: "4%",
        bottom: "3%",
        containLabel: true,
      },
      tooltip: {
        trigger: "axis",
        axisPointer: {
          type: "shadow",
        },
      },
    });
    this.rankList = this.dashboard && this.dashboard.shop_rank ? this.dashboard.shop_rank : [];
  },
  watch: {
    dashboard: {
      handler(nval, oval) {
        if (this.dashboard.shop_month) {
          let times = [];
          let datas = [];
          for (let i = 0; i < this.dashboard.shop_month.length; i++) {
            let item = this.dashboard.shop_month[i];
            times.push(item.OrderDate);
            datas.push(this.$round(item.total));
          }
          this.mycharts.setOption({
            title: {
              text: "月度销售额趋势",
            },
            xAxis: {
              data: times,
            },
            series: [
              {
                data: datas,
              },
            ],
          });
          this.rankList = this.dashboard.shop_rank;
        }
      },
      deep: true,
      immediate: false,
    },
  },
  methods: {
    setDay() {
      const day = dayjs().format("YYYY-MM-DD");
      this.date = [day, day];
    },
    setWeek() {
      const start = dayjs().day(1).format("YYYY-MM-DD");
      const end = dayjs().day(7).format("YYYY-MM-DD");
      this.date = [start, end];
    },
    setMonth() {
      const start = dayjs().startOf("month").format("YYYY-MM-DD");
      const end = dayjs().endOf("month").format("YYYY-MM-DD");
      this.date = [start, end];
    },
    setYear() {
      const start = dayjs().startOf("year").format("YYYY-MM-DD");
      const end = dayjs().endOf("yaer").format("YYYY-MM-DD");
      this.date = [start, end];
    },
  },
};
</script>

<style scoped>
.clearfix {
  display: flex;
  justify-content: space-between;
  position: relative;
}
.tab {
  width: 100%;
}
.right {
  position: absolute;
  right: 0px;
}

.date {
  width: 250px;
  margin: 0px 20px;
}

.right span {
  margin: 0px 10px;
}

.charts {
  width: 100%;
  height: 300px;
}

ul {
  list-style: none;
  width: 100%;
  height: 300px;
  padding: 0px;
}

ul li {
  height: 8%;
  margin: 10px 0px;
}

.rindex {
  float: left;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: black;
  color: white;
  text-align: center;
}

.rindex2 {
  float: left;
  width: 20px;
  height: 20px;
  text-align: center;
}

.rvalue {
  float: right;
}

.choose-item {
  cursor: pointer;
}
.choose-item:hover {
  color: red;
}
</style>
