<template>
  <div>
    <div class="header">
      <span class="saerch-header" style="color: #8a8a8a">会员总数</span>
    </div>
    <div class="main">
      <span class="main-title">15325</span>
      <span class="main-content">今日新增 <span>17.1</span>
        <svg
          t="1681137762109"
          class="icon"
          viewBox="0 0 1024 1024"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          p-id="2607"
          width="16"
          height="16"
        >
          <path
            d="M194.108395 621.186771 863.223758 621.186771 528.668123 251.67295Z"
            fill="#d81e06"
            p-id="2608"
          ></path>
        </svg>
      </span>
    </div>
    <div class="footer">
      <div class="charts" ref="charts"></div>
    </div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState({
      listState: (state) => state.home.list,
    }),
  },
  mounted() {
    let lineCharts = echarts.init(this.$refs.charts);
    lineCharts.setOption({
      xAxis: {
        show: false,
        type: "category",
      },
      yAxis: {
        show: false,
        min: 0,
        max: 100,
      },
      series: [
        {
          type: "line",
          data: [40, 50, 33, 66, 80, 65, 24, 44, 65, 24, 44],
          itemStyle: {
            opacity: 0,
          },
          lineStyle: {
            color: "#409eff",
          },
          areaStyle: {
            color: {
              type: "linear",
              x: 0,
              y: 0,
              x2: 0,
              y2: 1,
              colorStops: [
                {
                  offset: 0,
                  color: "#409eff", // 0% 处的颜色
                },
                {
                  offset: 1,
                  color: "#fff", // 100% 处的颜色
                },
              ],
              global: false, // 缺省为 false
            },
          },
          smooth: true,
        },
      ],
      grid: {
        left: "0px",
        top: "0px",
        right: "0px",
        bottom: "0px",
      },
    });
  },
};
</script>

<style scoped>
.header {
  display: flex;
  align-items: center;
}

.search-header {
  margin-right: 20px;
}

.main {
  margin: 10px 0px;
}

.main-title {
  margin-right: 30px;
  font-size: 30px;
}
.main-content {
  margin-right: 10px;
  color: #8a8a8a;
}
.main-content svg {
  vertical-align: middle;
}
.charts {
  width: 100%;
  height: 50px;
}
</style>
